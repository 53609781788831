import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public addActiveClass: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  display(value: boolean) {
    this.status.next(value);
  }

  //setting active class in the header section on project tab expand 

  addActiveClassObs = this.addActiveClass.asObservable();

  addActiveClassFunction(active: boolean) {
    this.addActiveClass.next(active);
  }

}
