import { Component, OnInit, HostListener } from '@angular/core';
import { SelectItem, SelectItemGroup, TreeNode, MenuItem } from 'primeng/api';
import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
import { CustomValidator } from '../helpers/CustomValidator';
import { ManageContractorService } from '../services/manage-contractor.service';
import { Message } from 'primeng/api';
import { TranslateService } from '../translator/translate.service';
import { Router } from '@angular/router';

interface ContractingCompany {
  companyCode: string,
  companyName: string,
  companyDisplayName: string,
  country: string,
  region: string
}
interface UserDetail {
  FirstName: string,
  LastName: string,
  PrimaryEmail: string,
  MobileNumber: string,
  JobTitle: string,
  Status: boolean,
  StartDate: Date,
  EndDate: Date,
  LastValidatedDate: Date,
  OrgID: string,
  OrgName: string,
  CompanyID: string,
  CompanyName: string,
  CreatedBy: string,
  ResponsiblePerson: string,
  CreatedBySystem: string
}
interface UserCache {
  upn: string,
  oid: string,
  role: string
}
interface Country {
  id: number,
  countryname: string,
  phonecode: string,
  countrycode: string
}

@Component({
  selector: 'app-add-contractor',
  templateUrl: './add-contractor.component.html',
  styleUrls: ['./add-contractor.component.css']
})

export class AddContractorComponent implements OnInit {
  applications: any[];
  phoneCodes: SelectItemGroup[];
  companyList: SelectItem[];
  //selectedCompany: SelectItem;
  selectedCompany: ContractingCompany;
  selectedCountry: Country;
  countryList: Country[];
  deptPopupDisplay: boolean = false;
  companyListDB: ContractingCompany[];
  deptTree: TreeNode[]
  selectedDeptPopup: TreeNode;
  selectedDeptPage: TreeNode;
  selectedDeptLabelPopup: string;
  selectedDeptLabelPage: string;
  isUserauthorized: boolean = false;
  minDate: Date;
  maxDate: Date;
  defaultEndDate: Date;
  selectedEndDate: Date;
  userData: UserDetail;
  deptTreeLoading: boolean = false;
  contractors: any[];
  validationMessages: Message[] = [];
  filteredCompanies: any[];
  menuItems: MenuItem[];
  home: MenuItem;
  noResult: string;
  mobileNumber: string;
  userCache: UserCache = <UserCache>{};

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    if (!this.hasEnteredAnyValue()) {
      return true;
    } else {
      return false; //cannot set custom message.
    }
  }

  canDeactivate() {
    if (!this.hasEnteredAnyValue()) {
      return true;
    } else {
      return confirm(this.translate.data['MSGONPAGECLOSE']);
    }
  }

  constructor(private _userService: UserService, private translate: TranslateService,
    private _router: Router, private _loaderService: LoaderService, private _manageContractorService: ManageContractorService) { }

  ngOnInit() {
    this.roleValidation();
    this.loadCountryDropdown();
    this.loadDepartmentDropdown();
  }

  roleValidation() {
    this._userService.GetCachedUser().then(result => {
      this.userCache.upn = result[0].user_id;
      this.userCache.oid = result[0].user_claims.find(t => t.typ.endsWith('objectidentifier')).val;

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.Admin')) {
        this.userCache.role = "EIMPortal.Admin";
      } else {
        this.userCache.role = result[0].user_claims.find(t => t.typ == 'roles').val;
      }

      if (this.userCache.role == "EIMPortal.Admin") {
        this.loadInitialValues();
        this.isUserauthorized = true;
      } else {
        this.isUserauthorized = false;
        this._router.navigate(['login/']);
      }
    });
  }

  loadInitialValues() {
    let today = new Date();
    this.minDate = new Date();
    this.minDate.setDate(today.getDate());

    this.maxDate = new Date();
    this.maxDate.setFullYear(today.getFullYear() + 1);

    this.defaultEndDate = new Date();
    this.defaultEndDate.setDate(today.getDate() + 90);
    this.selectedEndDate = this.defaultEndDate;
    this.userData = {
      FirstName: '', LastName: '', MobileNumber: '', PrimaryEmail: '', JobTitle: '', StartDate: this.minDate,
      EndDate: this.selectedEndDate, Status: true, LastValidatedDate: this.minDate, OrgID: '', OrgName: '',
      CompanyID: '', CompanyName: '', CreatedBy: this.userCache.upn, ResponsiblePerson: this.userCache.upn, CreatedBySystem: ''
    };
    this.mobileNumber = '';
  }

  loadCountryDropdown() {
    const countrypromise = this._userService.GetCountryList();
    countrypromise.then(result => {
      this.countryList = result;
      let countries: SelectItem[] = this.countryList.map<SelectItem>(cn =>
        ({ label: cn.countryname, value: cn }));
      this.phoneCodes = [{
        label: '--Primary--',
        items: [
          { label: "Australia", value: { id: 13, countryname: "Australia", phonecode: "+61", countrycode: "AU" } },
          { label: "Chile", value: { id: 283, countryname: "Chile", phonecode: "+56", countrycode: "CL" } },
          { label: "Singapore", value: { id: 195, countryname: "Singapore", phonecode: "+65", countrycode: "SG" } },
          { label: "United States", value: { id: 470, countryname: "United States", phonecode: "+1", countrycode: "US" } }
        ]
      },
      {
        label: '--Others--',
        items: countries
      }];
    }).catch(error => {
      console.log(error);
    });
  }

  loadDepartmentDropdown() {
    const deptdatapromise = this._userService.GetDepartments("0");
    deptdatapromise.then(result => {
      this.deptTree = result;
    }).catch(error => {
      console.log(error);
    });
  }

  showDeptDialog() {
    this.deptPopupDisplay = true;
  }

  deptPopupOK() {
    if (this.selectedDeptPopup != undefined) {
      this.selectedDeptPage = this.selectedDeptPopup;
      this.selectedDeptLabelPage = this.selectedDeptLabelPopup;
      //this.validateForCreateButtonEnable();
    }
    else { this.selectedDeptPage = null; }
    this.deptPopupDisplay = false;
  }

  deptPopupCancel() {
    this.deptPopupDisplay = false;
  }

  onDeptNodeSelect(node: TreeNode) {
    this.selectedDeptLabelPopup = node.label;
    this.setSelectedDeptParentLabelPopup(node);
  }

  onDeptNodeExpand(event) {
    if (event.node) {
      this.deptTreeLoading = true;
      this._userService.GetDepartments(event.node.data).then(nodes => {
        event.node.children = nodes;
        this.deptTreeLoading = false;
      }).catch(error => {
        console.log(error);
      });
    }
  }

  setSelectedDeptParentLabelPopup(_selectedDept: TreeNode) {
    if (_selectedDept.parent != undefined && _selectedDept.parent != null) {
      this.selectedDeptLabelPopup = _selectedDept.parent.label + ' > ' + this.selectedDeptLabelPopup;
      this.setSelectedDeptParentLabelPopup(_selectedDept.parent);
    };
  }

  setSelectedDeptParentLabelPage(_userDept: TreeNode) {
    if (_userDept.parent != undefined && _userDept.parent != null) {
      this.selectedDeptLabelPage = _userDept.parent.label + ' > ' + this.selectedDeptLabelPage;
      this.setSelectedDeptParentLabelPage(_userDept.parent);
    };
  }

  hasEnteredAnyValue(): boolean {
    if ((this.userData.FirstName && this.userData.FirstName.trim() != "") ||
      (this.userData.LastName && this.userData.LastName.trim() != "") ||
      (this.userData.PrimaryEmail && this.userData.PrimaryEmail.trim() != "") ||
      (this.userData.MobileNumber && this.userData.MobileNumber.trim() != "") ||
      (this.selectedCountry) ||
      (this.selectedDeptPage) ||
      (this.selectedCompany)) {
      return true;
    } else {
      return false;
    }
  }

  validateonSubmit(): boolean {
    let fname, lname, country, mobile, enddate, dept, comp: boolean;
    fname = this.firstNameValidate(this.userData.FirstName);
    lname = this.lastNameValidate(this.userData.LastName);
    if (this.selectedCountry == null || this.selectedCountry == undefined) { country = false; }
    else { country = true; }
    if (this.mobileNumber == null || this.mobileNumber == undefined || this.mobileNumber.trim() == "" || /[^0-9]/g.test(this.mobileNumber)) { mobile = false; }
    else { mobile = true; }
    enddate = this.endDateValidate();
    dept = this.departmentValidate();
    comp = this.companyValidate();
    this.validateEmailIdOnly(this.userData.PrimaryEmail);
    if (fname && lname && country && mobile && enddate && dept && comp) {
      return true;
    } else {
      return false;
    }
  }

  createEIMUser() {
    if (this.validateonSubmit()) {
      if (!CustomValidator.emailValidator(this.userData.PrimaryEmail)) {
        this.AddValiationMessage('emailpattern', this.translate.data['VALIDATEEMAILPATTERN']);
      }
      else {
        this.RemoveValidationMessage('emailpattern');
        if (this.validateEmailDomain(this.userData.PrimaryEmail)) {
          this.RemoveValidationMessage('emailbhpdomain');
          this.CreateUserOnConfirm();
        } else {
          this.AddValiationMessage('emailbhpdomain', this.translate.data['VALIDATEBHPMAIL']);
        }
      }
    }
  }

  private CreateUserOnConfirm() {
    let _mobileNumber = this.selectedCountry.phonecode + ' ' + this.mobileNumber.replace(/\s/g, "");
    this.userData.MobileNumber = _mobileNumber;
    if (this.selectedCompany) {
      this.userData.CompanyID = this.selectedCompany.companyCode;
    }
    if (this.selectedDeptPage) {
      this.userData.OrgID = this.selectedDeptPage.data;
    }
    this._loaderService.display(true);
    this.userData.CreatedBy = this.userCache.upn;
    this.userData.ResponsiblePerson = this.userCache.upn;
    this.userData.CreatedBySystem = "EIMPortal";
    let request = {
      requestBody: this.userData,
      auditData: {
        userObjectId: this.userCache.oid,
        user: this.userCache.upn,
        userType: "Admin",
        eventType: "Create User"
      }
    };
    this.RemoveValidationMessage('createuser');
    this.RemoveValidationMessage('createuser-MOBILEPHONE');
    this.RemoveValidationMessage('createuser-EMAIL');
    this.RemoveValidationMessage('createuser-EMAIL_FORMAT');
    this._userService.CreateEIMUser(request).then(result => {
      console.log(result);
      if (result != null && result != undefined) {
        if (result.isSuccess) {
          this.validationMessages.push({ id: 'createuser', severity: 'success', summary: this.translate.data['MSGUSERCREATED'] });
          this.clearValues();
        } else {
          if (result.errorList && result.errorList.length > 0) {
            for (var i = 0; i < result.errorList.length; i++) {
              let error = result.errorList[i];
              if (error.errorKey == 'MOBILEPHONE') {
                this.validationMessages.push({ id: 'createuser-' + error.errorKey, severity: 'error', summary: this.translate.data['VALIDATEEXISTMOBNO'].replace('[UPN]', error.data) });
              } else if (error.errorKey == 'EMAIL') {
                this.validationMessages.push({ id: 'createuser-' + error.errorKey, severity: 'error', summary: this.translate.data['VALIDATEEXISTMAIL'].replace('[UPN]', error.data) });
              } else if (error.errorKey == 'EMAIL_FORMAT') {
                this.validationMessages.push({ id: 'createuser-' + error.errorKey, severity: 'error', summary: this.translate.data['VALIDATEEMAILPATTERN'] });
              } else if (error.errorKey == 'DOMAIN_BLOCKED') {
                this.validationMessages.push({ id: 'createuser-' + error.errorKey, severity: 'error', summary: this.translate.data['VALIDATEBLOCKEDDOMAIN'] });
              }
            }
          } else {
            this.validationMessages.push({ id: 'createuser', severity: 'error', summary: result.message });
          }
        }
      }
      else {
        this.validationMessages.push({ id: 'createuser', severity: 'error', summary: 'No response from EIMService' });
      }
      this._loaderService.display(false);
    }).catch(error => {
      this.clearValues();
      console.log(error);
      this.validationMessages.push({ id: 'createuser', severity: 'error', summary: error });
      this._loaderService.display(false);
    });
  }

  clearValues() {
    this.userData = {
      FirstName: '', LastName: '', MobileNumber: '', PrimaryEmail: '', JobTitle: '', StartDate: this.minDate,
      EndDate: this.selectedEndDate, Status: true, LastValidatedDate: this.minDate, OrgID: '', OrgName: '',
      CompanyID: '', CompanyName: '', CreatedBy: this.userCache.upn, ResponsiblePerson: this.userCache.upn, CreatedBySystem: ''
    };
    this.mobileNumber = '';
    this.selectedCountry = null;
    this.selectedCompany = null;
    this.selectedDeptPage = null;
    this.selectedDeptLabelPage = null;
  }

  numberOnly($event): boolean {
    return CustomValidator.numberValidator($event);
  }

  AlphaNumericValidator($event): boolean {
    return CustomValidator.AlphaNumericValidator($event);
  }

  filterCompanies(event) {
    let query = event.query.trim();
    if (query.length > 2) {
      this._userService.GetContractingCompanies(query, false)
        .then(result => {
          if (result != null && result != undefined) {
            this.companyListDB = result;
          } else {
            this.companyListDB = [];
            this.noResult = "No External Organisation found";
          }
        }).catch(error => {
          console.log(error);
        });
    }
  }

  validateEmailIdOnly(value: any) {
    if (!CustomValidator.emailValidator(value)) {
      this.AddValiationMessage('emailpattern', this.translate.data['VALIDATEEMAILPATTERN']);
    }
    else {
      this.RemoveValidationMessage('emailpattern');
      if (this.validateEmailDomain(value)) {
        this.RemoveValidationMessage('emailbhpdomain');
      } else {
        this.AddValiationMessage('emailbhpdomain', this.translate.data['VALIDATEBHPMAIL']);
      }
    }
  }

  validateEmailDomain(email): boolean {
    let valid = true;
    if (email && email.indexOf("@") != -1) {
      let [_, domain] = email.split("@");
      if (domain == "bhp.com" || domain == "bhpbilliton.com") {
        valid = false;
      }
    }
    return valid;
  }

  firstNameValidate(fname: any): boolean {
    let valid = true;
    if (fname == null || fname == undefined || fname.trim() == "") {
      valid = false;
      this.AddValiationMessage('fname', this.translate.data['VALIDATEFN']);
    } else {
      this.RemoveValidationMessage('fname');
    }
    return valid;
  }

  lastNameValidate(lname: any): boolean {
    let valid = true;
    if (lname == null || lname == undefined || lname.trim() == "") {
      valid = false;
      this.AddValiationMessage('lname', this.translate.data['VALIDATELN']);
    } else {
      this.RemoveValidationMessage('lname');
    }
    return valid;
  }

  mobilePhoneValidate(phone: any): boolean {
    let valid = true;
    if (phone == null || phone == undefined || phone.trim() == "" || /[^0-9]/g.test(phone)) {
      valid = false;
      this.AddValiationMessage('phone', this.translate.data['VALIDATEMOBNO']);
    } else {
      this.RemoveValidationMessage('phone');
      //if (this.selectedCountry) {
      //  this.mobileNumberDuplicationCheck();
      //}
    }
    return valid;
  }

  endDateValidate(): boolean {
    let valid = true;
    if (this.selectedEndDate == null || this.selectedEndDate == undefined) {
      valid = false;
      this.AddValiationMessage('enddate', this.translate.data['VALIDATEENDDATE']);
    } else {
      this.RemoveValidationMessage('enddate');
    }
    return valid;
  }

  companyValidate(): boolean {
    let valid = true;
    if (this.selectedCompany == null || this.selectedCompany == undefined) {
      valid = false;
      this.AddValiationMessage('company', this.translate.data['VALIDATEEXTORG']);
    } else {
      this.RemoveValidationMessage('company');
    }
    return valid;
  }

  departmentValidate(): boolean {
    let valid = true;
    if (this.selectedDeptPage == null || this.selectedDeptPage == undefined) {
      valid = false;
      this.AddValiationMessage('department', this.translate.data['VALIDATEDEPT']);
    } else {
      this.RemoveValidationMessage('department');
    }
    return valid;
  }

  countryCodeValidate(): boolean {
    let valid = true;
    if (this.selectedCountry == null || this.selectedCountry == undefined) {
      valid = false;
      this.AddValiationMessage('country', this.translate.data['VALIDATECOUNTRY']);
    } else {
      this.RemoveValidationMessage('country');
      //if (this.mobileNumber && this.mobileNumber.trim() != "") {
      //  this.mobileNumberDuplicationCheck();
      //}
    }
    return valid;
  }

  private AddValiationMessage(id: string, summary: string) {
    let msgExist = this.validationMessages.find(msg => msg.id == id);
    if (!msgExist) {
      this.validationMessages.push({ id: id, severity: 'error', summary: summary });
    }
  }

  private RemoveValidationMessage(id: string) {
    for (var i = this.validationMessages.length - 1; i >= 0; i--) {
      if (this.validationMessages[i].id === id) {
        this.validationMessages.splice(i, 1);
      }
    }
  }

}
