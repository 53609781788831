import { Injectable, Injector } from "@angular/core";
import { ConnectionBackend, RequestOptions, Request, RequestOptionsArgs, Response, Http, Headers } from "@angular/http";
import { Observable } from "rxjs";
import { catchError, window } from 'rxjs/operators';

@Injectable()
export class InterceptedHttp extends Http {

  constructor(backend: ConnectionBackend, defaultOptions: RequestOptions) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    return super.request(url, options).pipe(
      catchError(this.handleError));
  }

  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.get(url, this.getRequestOptionArgsForHttpGetAndPost(options));
  }

  post(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.post(url, body, this.getRequestOptionArgsForHttpGetAndPost(options));
  }

  put(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.put(url, body, this.getRequestOptionArgs(options));
  }

  delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
    return super.delete(url, this.getRequestOptionArgs(options));
  }

  private getRequestOptionArgs(options?: RequestOptionsArgs): RequestOptionsArgs {
    //if (options == null) {
    //  options = new RequestOptions();
    //}
    //if (options.headers == null) {
    //  options.headers = new Headers();
    //}

    return options;
  }

  private getRequestOptionArgsForHttpGetAndPost(options?: RequestOptionsArgs): RequestOptionsArgs {
    if (options == null) {
      options = new RequestOptions();
    }
    if (options.headers == null) {
      options.headers = new Headers();
    }

    //if (/edge\//i.test(navigator.userAgent) || /Trident\//i.test(navigator.userAgent)) {
    options.headers.append('Cache-Control', 'no-store');
    options.headers.append('Pragma', 'no-cache');
    options.headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    //}

    return options;
  }

  private handleError = (error: Response) => {
    //403 Forbidden also can be catched
    if ([401].indexOf(error.status) !== -1) {
      // reloads(prompt login) if 401 Unauthorized response returned from api      
      //location.reload(true);
      location.href = location.origin + '/login';
    }

    return Observable.throw(error)
  }
}
