import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../app/services/common.service';
import { UserService } from '../services/user.service';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  enstatus: boolean = true;
  esstatus: boolean = false;
  language: any[];
  appPopup = false;
  apps: any;
  selectedLang: any;
  supportmail: string;
  //enableforgotpwd: boolean;

  alertstatus: boolean = true;
  userEmail: string = "";
  adalSvc: any;
  constructor(private common: CommonService, private _userService: UserService,
    private appconfig: ConfigurationService) {
    this.language = [
      { name: 'English', code: 'en' },
      { name: 'Español', code: 'es' }
    ];
    this.selectedLang = { name: 'English', code: 'en' };
    this.supportmail = appconfig.config.supportmail;
    //this.enableforgotpwd = appconfig.config.forgotpassword;
  }

  setLang(eventOpt) {
    if (eventOpt.code == 'en') { this.enstatus = true; this.esstatus = false; } else { this.enstatus = false; this.esstatus = true; }
    if (eventOpt.code == 'es') { this.esstatus = true; this.enstatus = false; } else { this.esstatus = false; this.enstatus = true; }
    this.common.setLang(eventOpt.code);
  }

  validateUserEmail() {
    if (this.userEmail.trim() != "") {
      this._userService.ValidateUserEmail(this.userEmail.trim()).then(result => {
        if (result == "!wD|J0n5y`Qi7M)B") {
          this.alertstatus = true;
          window.open("/.auth/login/aad?post_login_redirect_url=/managecontractor&login_hint=" + this.userEmail.trim(), "_self");
        } else if (result == "h*R,@!7P[ycMK4k;") {
          this.alertstatus = true;
          window.open("/.auth/login/aad?post_login_redirect_url=/home&login_hint=" + this.userEmail.trim(), "_self");
        }
        else { this.alertstatus = false; return false; }
      }).catch(error => {
        console.log(error);
      });
    }
    else
      this.alertstatus = false;
    return false;
  }

  getAzureADApps() {
    this.common.GetAzureADApps().then(result => {
      console.log(result);
      this.apps = result; 
    }).catch(error => {
      console.log(error);
    });
  }

  showAppPopup() {
    this.appPopup = true;
  }

  closeAppPopup() {
    this.appPopup = false;
  }

  ngOnInit() {
    this.getAzureADApps();
  }

}
