export class Constants {

  public static ApplicationTitle = 'External Contractor Management System';
  public static ServerError = 'Internal Server Error';
  public static SplitString = "|";
  public static GET_USER_DETAIL_BY_ID = "/api/user/getuserbyid?id=";  
  public static GET_AUDIT_HISTORY = "/api/user/GetAuditHistory";  
  public static VALIDATE_USER_LOGINID = "/api/user/validateuseraccess?id=";
  public static GET_CONTRACTORS = "/api/user/getcontractors";
  public static GET_COUNTRY_LIST = "/api/user/getcountrylist";
  public static UPDATE_USER_STATUS = "api/user/UpdateUserStatus";
  public static GET_CONTRACTING_COMPANIES = "api/user/GetContractingCompanies?searchText=";
  public static GET_DEPARTMENT_TREE = "api/user/GetDepartmentTree?parentid=";
  public static GET_DEPARTMENT_PATH = "api/user/GetDepartmentPath?deptid=";
  public static CREATE_EIM_USER = "api/user/CreateEIMUser";
  public static UPDATE_EIM_USER = "api/user/UpdateEIMUser";
  public static ENABLE_EIMUSER = "api/user/EnableEIMUser";
  public static DISABLE_EIMUSER = "api/user/DisableEIMUser";
  public static RESET_USER_PASSWORD = "api/user/ResetUserPassword";
  public static DELETE_USER = "api/user/DeleteUser";
  public static UPLOAD_BULKUSERS = "api/user/UploadBulkUser";
  public static SEND_SMS_CODE = "/api/user/SendOtpSms?upn=";
  public static VERIFY_OTP_CODE = "/api/user/VerifyOtpCode";
  public static SEND_EMAIL_CODE = "/api/user/SendOtpEmail?upn=";
  public static VERIFY_EMAIL_CODE = "/api/user/VerifyOtpEmail";
  public static GET_EIMUSER_UPN = "api/user/GetEimUserUpn?searchText=";
}
