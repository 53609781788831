import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
import { ConfigurationService } from '../services/configuration.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '../translator/translate.service';

interface SmsResponse {
  carrier: string;
  is_cellphone: string;
  message: string;
  seconds_to_expire: number;
  uuid: string;
  success: boolean;
  mobilenumber: string;
}

interface VerifyOtpResponse {
  message: string;
  success: boolean;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

  enstatus: boolean = true;
  esstatus: boolean = false;
  language: any[];
  selectedLang: any;
  enableforgotpwd: boolean;
  visibleloginid: boolean = false;

  codeSent: boolean = false;
  smsresponse: SmsResponse;
  otpverifyresponse: VerifyOtpResponse;

  message: string = '';
  validationmessage: string = '';
  upn: string;
  otp: string;
  config: any;
  disableControl: boolean = false;
  alertstatus: boolean = true;
  upntenant: string;

  constructor(private common: CommonService, private _loaderService: LoaderService, private route: ActivatedRoute,
    private _userService: UserService, private appconfig: ConfigurationService, private translate: TranslateService) {
    this.language = [
      { name: 'English', code: 'en' },
      { name: 'Español', code: 'es' }
    ];
    this.selectedLang = { name: 'English', code: 'en' };
    //this.enableforgotpwd = appconfig.config.forgotpassword;
    this.upntenant = appconfig.config.upntenant;
  }

  setLang(eventOpt) {
    if (eventOpt.code == 'en') { this.enstatus = true; this.esstatus = false; } else { this.enstatus = false; this.esstatus = true; }
    if (eventOpt.code == 'es') { this.esstatus = true; this.enstatus = false; } else { this.esstatus = false; this.enstatus = true; }
    this.common.setLang(eventOpt.code);
  }

  ngOnInit() {
  }

  SendSmsCode() {
    if (this.upn != "" && this.upn != undefined) {
      if (this.upn.endsWith(this.appconfig.config.upntenant)) {
        this._loaderService.display(true);
        this._userService.SendSmsCode(this.upn).then(result => {
          if (result != undefined) {
            this.smsresponse = result;
            if (this.smsresponse.success) {
              this.codeSent = true;
              this.visibleloginid = false;
              this.disableControl = true;
              this.message = this.translate.data['MSGSMSMOB'] + '(' +
                this.smsresponse.mobilenumber + ').';
              this.validationmessage = '';
              this.alertstatus = true;
            } else {
              this.alertstatus = false;
              this.validationmessage = this.smsresponse.message;
            }
          }
          this._loaderService.display(false);
        }).catch(error => {
          this._loaderService.display(false);
        });
      } else {
        this.alertstatus = false;
        this.validationmessage = 'Invalid Login Id';
      }
    } else {
      this.alertstatus = false;
      this.validationmessage = 'Invalid Login Id';
    }
  }

  VerifyOtp() {
    if (this.otp != "" && this.upn != undefined) {
      this._loaderService.display(true);
      this._userService.VerifyOtpCode(this.upn, this.otp).then(result => {
        if (result != undefined) {
          this.otpverifyresponse = result;
          if (this.otpverifyresponse.success) {
            this.codeSent = false;
            this.disableControl = false;
            this.message = this.translate.data['FORGOTPWDSUCCESS'];
            this.validationmessage = '';
            this.upn = '';
            this.otp = '';
            this.alertstatus = true;
          } else {
            this.alertstatus = false;
            this.validationmessage = this.otpverifyresponse.message;
          }
        }
        this._loaderService.display(false);
      }).catch(error => {
        this._loaderService.display(false);
      });
    } else {
      this.alertstatus = false;
      this.validationmessage = 'Invalid Otp';
    }
  }
}
