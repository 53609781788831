import { Injectable } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Helper } from '../helpers/helper';
import { Constants } from '../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class ManageContractorService {
  contractor: any;

  constructor(private _http: Http) { }

  getContractors(searchdata: any): any {
    return this._http.post(Constants.GET_CONTRACTORS, searchdata).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

}
