import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { LoaderService } from '../services/loader.service';

export interface Applications {
  AppName: string,
  Requested: boolean,
  Approved: boolean,
  RequestEnabled: boolean,
  ApprovedEnabled: boolean
}
interface UserCache {
  upn: string,
  oid: string,
  role: string
}
interface ContractingCompany {
  companyCode: string,
  companyName: string,
  country: string,
  region: string
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  providers: [UserService],
})

export class LandingComponent implements OnInit {
  applications: any;

  username = '';
  useremail = '';
  userInfo: any;
  isUserauthorized: boolean = false;
  companyName: string = '';
  departmentName: string = '';
  userCache: UserCache = <UserCache>{};

  constructor(private _router: Router,
    private _userService: UserService, private _loaderService: LoaderService) {
  }

  ngOnInit() {
    this.initFunctions();
  }

  private initFunctions() {
    this._userService.GetCachedUser().then(result => {
      this.userCache.upn = result[0].user_id;
      this.userCache.oid = result[0].user_claims.find(t => t.typ.endsWith('objectidentifier')).val;

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.Contractor')) {
        this.userCache.role = "EIMPortal.Contractor";
      } else {
        this.userCache.role = result[0].user_claims.find(t => t.typ == 'roles').val;
      }

      if (this.userCache.role == "EIMPortal.Contractor") {
        this._loaderService.display(true);
        this._userService.GetUserProfile(this.userCache.oid).then(response => {
          this.userInfo = response;
          this.LoadCompanyDataandSelectValue();
          this.LoadDepartment();
          this.isUserauthorized = true;
        });
        this._loaderService.display(false);
      } else {
        this.isUserauthorized = false;
        this._router.navigate(['login/']);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  enableField(userInfo: any) {
    if (userInfo.status == false || userInfo.accountType == 'B2B') {
      return false;
    } else {
      return true;
    }
  }

  private LoadDepartment() {
    if (this.userInfo.orgID != undefined) {
      this._userService.GetDepartmentPath(this.userInfo.orgID).then(result => {
        let deptNode = result;
        if (deptNode != undefined) {
          if (deptNode.message != undefined) {
            this.departmentName = deptNode.message;
          }
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }

  private LoadCompanyDataandSelectValue() {
    this._userService.GetContractingCompanies(this.userInfo.companyID, true, this.userInfo.companyID).then(result => {
      if (result) {
        let companyResult: ContractingCompany[] = result;
        let selectedCompany: ContractingCompany = companyResult.find(comp => comp.companyCode == this.userInfo.companyID);
        if (selectedCompany) {
          this.companyName = selectedCompany.companyName + " | " + selectedCompany.region + " | " + selectedCompany.country;
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  changePassword() {
    window.open("https://account.activedirectory.windowsazure.com/ChangePassword.aspx", "_blank");
  }
}
