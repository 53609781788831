import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../app/services/common.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  enstatus: boolean = true;
  esstatus: boolean = false;

  language: any[];
  selectedLang: any;

  userName: any;
  adalSvc: any;
  constructor(private common: CommonService, private _userService: UserService) {
  }

  setLang(eventOpt) {
    if (eventOpt.code == 'en') { this.enstatus = true; this.esstatus = false; } else { this.enstatus = false; this.esstatus = true; }
    if (eventOpt.code == 'es') { this.esstatus = true; this.enstatus = false; } else { this.esstatus = false; this.enstatus = true; }
    this.common.setLang(eventOpt.code);

    this._userService.UpdateUserLanguage({ upn: this.adalSvc[0].user_id, language: eventOpt.code }).then(result => {
      //console.log(result);
    }).catch(error => {
      console.log(error);
    });
  }

  logout() {
    window.open('/.auth/logout?post_logout_redirect_uri=/login', '_self');
  }

  ngOnInit() {
    this.language = [
      { name: 'English', code: 'en' },
      { name: 'Español', code: 'es' }
    ];

    this._userService.GetCachedUser().then(result => {
      this.adalSvc = result;
      this.userName = this.adalSvc[0].user_claims.find(t => t.typ == 'name').val;
      this._userService.GetUserLanguage(this.adalSvc[0].user_id).then(savedLang => {
        if (savedLang == undefined || savedLang == '') {
          savedLang = 'en';
        }
        if (savedLang == 'en') { this.enstatus = true; this.esstatus = false; } else { this.enstatus = false; this.esstatus = true; }
        if (savedLang == 'es') { this.esstatus = true; this.enstatus = false; } else { this.esstatus = false; this.enstatus = true; }
        this.common.setLang(savedLang);

        if (savedLang == 'es') {
          this.selectedLang = { name: 'Español', code: 'es' };
        } else {
          this.selectedLang = { name: 'English', code: 'en' };
        }

      }).catch(error => {
        console.log(error);
      });
    });

  }

}
