import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FileUploadModule } from 'primeng/fileupload';
import { UserService } from '../services/user.service';
import { Message, TreeNode, SelectItem } from 'primeng/api';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';
import { TranslateService } from '../translator/translate.service';

interface ContractingCompany {
  companyCode: string,
  companyName: string,
  companyDisplayName: string,
  country: string,
  region: string
}

interface UserCache {
  upn: string,
  oid: string,
  role: string,
  name: string
}

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})

export class BulkUploadComponent implements OnInit {
  validationMessages: Message[] = [];
  companyList: SelectItem[];
  selectedCompany: ContractingCompany;
  companyListDB: ContractingCompany[];
  deptTree: TreeNode[]
  selectedDeptPopup: TreeNode;
  deptTreeLoading: boolean = false;
  filteredCompanies: any[];
  noResult: string;
  isUserauthorized: boolean = false;
  selectedDeptSapId: string;
  selectedCompanySapId: string;
  userCache: UserCache = <UserCache>{};

  constructor(private _userService: UserService, private _loaderService: LoaderService, private _router: Router, private translate: TranslateService) { }

  ngOnInit() {
    this.roleValidation();
    this.loadDepartmentDropdown();
  }

  roleValidation() {
    this._userService.GetCachedUser().then(result => {
      this.userCache.upn = result[0].user_id;
      this.userCache.oid = result[0].user_claims.find(t => t.typ.endsWith('objectidentifier')).val;
      this.userCache.name = result[0].user_claims.find(t => t.typ == 'name').val;

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.SuperAdmin')) {
        this.userCache.role = "EIMPortal.SuperAdmin";
      } else if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.Admin')) {
        this.userCache.role = "EIMPortal.Admin";
      } else {
        this.userCache.role = result[0].user_claims.find(t => t.typ == 'roles').val;
      }

      if (this.userCache.role == "EIMPortal.Admin" || this.userCache.role == 'EIMPortal.SuperAdmin') {
        this.isUserauthorized = true;
      } else {
        this.isUserauthorized = false;
        this._router.navigate(['login/']);
      }
    });
  }

  loadDepartmentDropdown() {
    const deptdatapromise = this._userService.GetDepartments("0");
    deptdatapromise.then(result => {
      this.deptTree = result;
    }).catch(error => {
      console.log(error);
    });
  }

  onDeptNodeSelect(node: TreeNode) {
    console.log(node);
    this.selectedDeptSapId = node.data;
  }

  onDeptNodeExpand(event) {
    if (event.node) {
      this.deptTreeLoading = true;
      this._userService.GetDepartments(event.node.data).then(nodes => {
        event.node.children = nodes;
        this.deptTreeLoading = false;
      }).catch(error => {
        console.log(error);
      });
    }
  }

  onCompanySelect() {
    if (this.selectedCompany) {
      this.selectedCompanySapId = this.selectedCompany.companyCode;
    }
  }

  filterCompanies(event) {
    let query = event.query.trim();
    if (query.length > 2) {
      const companydatapromise = this._userService.GetContractingCompanies(query, true);
      companydatapromise.then(result => {
        if (result != null && result != undefined) {
          this.companyListDB = result;
        } else {
          this.companyListDB = [];
          this.noResult = "No External Organisation found";
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }

  @ViewChild('inputFileUpload') inputFileUploadElem: any;
  uploadCSV(event) {
    let input = new FormData();
    input.append("file", event.files[0]);
    this._loaderService.display(true);
    this.validationMessages = [];
    this._userService.UploadBulkUser(input, this.userCache.upn, this.userCache.name).then(result => {
      if (result != null && result != undefined) {
        if (result.isSuccess) {
          this.validationMessages.push({ id: 'updatestatus', severity: 'success', summary: this.translate.data['MSGBULKSUCCESS'] });
        } else {
          if (result.message == 'FORMAT') {
            this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: this.translate.data['MSGBULKFORMAT'] });
          } else if (result.message == 'EMPTY') {
            this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: this.translate.data['MSGBULKEMPTY'] });
          } else if (result.message == 'COUNT') {
            this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: this.translate.data['MSGBULKCOUNT'] });
          }
        }
      }
      else {
        this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: 'No response from EIM Service' });
      }
      this.inputFileUploadElem.clear();
      this._loaderService.display(false);
    }).catch(error => {
      console.log(error);
      this._loaderService.display(false);
      this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: 'Unknown error' });
      this.inputFileUploadElem.clear();
    });
  }
}
