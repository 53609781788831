
import { Constants } from './constants';
import { Observable } from 'rxjs';

export class Helper {

  public static serializeObj(obj) {
    var result = [];
    for (var property in obj) {
      result.push(encodeURIComponent(property) + "=" + encodeURIComponent(obj[property]));


    }
    return result.join("&");
  }

  public static extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  public static HandleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : Constants.ServerError;
    console.log(errMsg);
    return Observable.throw(errMsg);
  }
}
