import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, Message, SelectItem } from 'primeng/api';
import { LoaderService } from '../services/loader.service';
import { ManageContractorService } from '../services/manage-contractor.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../translator/translate.service';
import { Table } from 'primeng/table';
import { UserService } from '../services/user.service';
import { InputSwitchModule } from 'primeng/inputswitch';

interface Status {
  label: string,
  value: boolean
}
interface UserCache {
  upn: string,
  oid: string,
  role: string
}

@Component({
  selector: 'app-manage-contractor',
  templateUrl: './manage-contractor.component.html',
  styleUrls: ['./manage-contractor.component.css']
})

export class ManageContractorComponent implements OnInit {
  @ViewChild('contractorData') private _table: Table;

  totalContractor: string;
  totalContractorCount: number;
  contractors: any[];
  contractorMaster: any[];
  columns: any[];
  lastLoaded: string;
  status: Status[];
  selectedContractors: any[];
  validationMessages: Message[] = [];

  startDateFilter: Date[];
  endDateFilter: Date[];

  selectedStatus: Status;
  filterStatus: SelectItem[];

  isSearchButtonDisabled: boolean = false;
  isUserauthorized: boolean = false;
  msgs: Message[];
  filteredStatus: boolean = null;
  filteredRecords: number = 200;
  managedBySelected: string = 'ALL';
  gridNoOfRecords: SelectItem[];
  managedByDropDown: SelectItem[];
  firstName: any;
  lastName: any;
  email: any;
  UPN: any;
  menuItems: MenuItem[];
  home: MenuItem;
  displaySearchGrid: boolean = false;
  userCache: UserCache = <UserCache>{};

  constructor(private _manageContractorService: ManageContractorService,
    private translate: TranslateService, private _userService: UserService,
    private _loaderService: LoaderService, private _confirmationService: ConfirmationService,
    private _router: Router, private ActRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.roleValidation();
    this.checkDeleteUserRedirect();
    this.createGridColumns();
    this.status = [{ label: 'Enabled', value: true },
    { label: 'Disabled', value: false }];
    this.filterStatus = [{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }, { label: 'All', value: null }];
    this.gridNoOfRecords = [{ label: "200", value: 200 }, { label: "400", value: 400 }, { label: "600", value: 600 }, { label: "800", value: 800 }];
    this.managedByDropDown = [{ label: "Me", value: "ME" }, { label: "All", value: "ALL" }];
  }

  roleValidation() {
    this._loaderService.display(true);
    this._userService.GetCachedUser().then(result => {
      this.userCache.upn = result[0].user_id;
      this.userCache.oid = result[0].user_claims.find(t => t.typ.endsWith('objectidentifier')).val;

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.Admin')) {
        this.userCache.role = "EIMPortal.Admin";
      } else {
        this.userCache.role = result[0].user_claims.find(t => t.typ == 'roles').val;
      }

      if (this.userCache.role == "EIMPortal.Admin") {
        this.isUserauthorized = true;
      } else {
        this.isUserauthorized = false;
        this._router.navigate(['login/']);
      }
      this._loaderService.display(false);
    });
  }

  checkDeleteUserRedirect() {
    this.ActRoute.queryParams.subscribe(params => {
      if (params.deleteuser == "true") {
        this.validationMessages.push({ id: 'deleteuser', severity: 'success', summary: params.upn + this.translate.data['MSGDELETEUSER'] });
      } else {
        this.validationMessages = [];
      }
    });
  }

  createGridColumns() {
    this.columns = [
      { field: 'givenName', header: 'FIRSTNAME', control: 'textbox', format: 'string' },
      { field: 'surname', header: 'LASTNAME', control: 'textbox', format: 'string' },
      { field: 'loginId', header: 'LOGINID', control: 'textbox', format: 'string' },
      { field: 'mail', header: 'EMAILID', control: 'textbox', format: 'string' },
      //{ field: 'mobilePhone', header: 'MOBNUMBER', control: 'textbox', format: 'string' },
      { field: 'department', header: 'DEPARTMENT', control: 'textbox', format: 'string' },
      { field: 'company', header: 'EXTERNALORG', control: 'textbox', format: 'string' },
      //{ field: 'managedBy', header: 'MANAGER', control: 'textbox', format: 'string' },
      //{ field: 'startDate', header: 'STARTDATE', control: 'textbox', format: 'date' },
      //{ field: 'endDate', header: 'ENDDATE', control: 'textbox', format: 'date' }
    ];
  }

  onCalenderFilterSelect(filterDate, contData) {
    let date = new Date(filterDate.toISOString());
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    let toFilterendDate = new Date(date.getTime() - userTimezoneOffset);

    contData.filter(toFilterendDate, 'endDate', 'lt');
  }

  onPagination(event) {
    let pageStart: string = event.first + 1;
    let pageEnd: string;
    if ((event.first + event.rows) > this.totalContractorCount) {
      pageEnd = this.totalContractorCount.toString();
    } else {
      pageEnd = event.first + event.rows;
    }
    this.totalContractor = pageStart + " to " + pageEnd + " of " + this.totalContractorCount + " users";
  }

  onFiltering(event) {
    this.totalContractorCount = event.filteredValue.length;
    if (event.filteredValue.length > 0) {
      if (event.filteredValue.length > 20) {
        this.totalContractor = "1 to 20" + " of " + event.filteredValue.length + " users";
      } else {
        this.totalContractor = "1 to " + event.filteredValue.length + " of " + event.filteredValue.length + " users";
      }
    } else {
      this.totalContractor = "";
    }
  }

  editUser(user: any) {
    this._confirmationService.confirm({
      message: this.translate.data['OPENEDITPAGE'] + user.displayName + "'?",
      header: this.translate.data['OPENEDITHEADER'],
      acceptLabel: this.translate.data['YES'],
      rejectLabel: this.translate.data['NO'],
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let match = /edge\//i.test(window.navigator.userAgent);
        if (match == true) {
          window.open("/editcontractor/" + user.id, "_self");
        } else {
          window.open("/editcontractor/" + user.id, "_blank");
        }
      },
      reject: () => {
        this._loaderService.display(false);
        return;
      }
    });
  }

  searchEIMUser() {
    this.displaySearchGrid = true;
    this.contractors = null;
    this._loaderService.display(true);
    this.lastLoaded = Date().toString();
    if (this._table)
      this._table.reset();

    var searchData =
    {
      firstName: this.firstName,
      lastName: this.lastName,
      upn: this.UPN,
      primaryEmail: this.email,
      status: this.filteredStatus,
      count: (this.filteredRecords == undefined) ? 200 : this.filteredRecords,
      managerId: (this.managedBySelected == "ME") ? this.userCache.upn : null
    };

    this._manageContractorService.getContractors(searchData).then(result => {
      this.contractors = result; this.contractorMaster = result;
      if (this.contractors != null && this.contractors != undefined) {
        this.totalContractorCount = this.contractors.length;
        if (this.contractors.length > 20) {
          this.totalContractor = "1 to 20" + " of " + this.contractors.length + " users";
        } else {
          this.totalContractor = "1 to " + this.contractors.length + " of " + this.contractors.length + " users";
        }
      }

      //Used for custom date filter with date picker      
      this._table.filterConstraints['startDateFilter'] = (value: Date, filter): boolean => {
        let incomingDate = new Date(value);
        let fromDate = new Date(this.startDateFilter[0].getFullYear(), this.startDateFilter[0].getMonth(), this.startDateFilter[0].getDate(), 0, 0, 0);
        let toDate = new Date(this.startDateFilter[1].getFullYear(), this.startDateFilter[1].getMonth(), this.startDateFilter[1].getDate(), 23, 59, 59);
        return (fromDate <= incomingDate && incomingDate <= toDate);
      }
      this._table.filterConstraints['endDateFilter'] = (value: Date, filter): boolean => {
        let incomingDate = new Date(value);
        let fromDate = new Date(this.endDateFilter[0].getFullYear(), this.endDateFilter[0].getMonth(), this.endDateFilter[0].getDate(), 0, 0, 0);
        let toDate = new Date(this.endDateFilter[1].getFullYear(), this.endDateFilter[1].getMonth(), this.endDateFilter[1].getDate(), 23, 59, 59);
        return (fromDate <= incomingDate && incomingDate <= toDate);
      }

      this._loaderService.display(false);
    }).catch(error => {
      //console.log(error);
    });
  }

}
