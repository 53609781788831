import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

interface UserCache {
  upn: string,
  oid: string,
  role: string
}

@Component({
  selector: 'app-adminnavbar',
  templateUrl: './adminnavbar.component.html',
  styleUrls: ['./adminnavbar.component.css']
})

export class AdminNavBarComponent implements OnInit {

  adalSvcUserInfo: any;
  isSuperAdmin: boolean = false;
  userCache: UserCache = <UserCache>{};

  constructor(private _userService: UserService) {
    //this.adalSvcUserInfo = this.adalSvc.userInfo;
  }

  ngOnInit() {
    this._userService.GetCachedUser().then(result => {
      this.userCache.upn = result[0].user_id;
      this.userCache.oid = result[0].user_claims.find(t => t.typ.endsWith('objectidentifier')).val;

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.SuperAdmin')) {
        this.userCache.role = "EIMPortal.SuperAdmin"; this.isSuperAdmin = true;
      } else {
        this.isSuperAdmin = false;
      }
    });
  }

}
