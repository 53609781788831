export class CustomValidator {

  static numberValidator(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  static AlphaNumericValidator(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 32 && charCode < 48) || (charCode > 57 && charCode < 65) || (charCode > 90 && charCode < 97) || (charCode > 122 && charCode < 127)) {
      return false;
    }
    return true;
  }

  static emailValidator(email): boolean {
    let isValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    if (isValid) {
        return true;
    } else {
      return false;
    }
  }

  static validateEmptyorNullorUndefined(value: any): boolean {
    let isValid = false;
    if (value == "" || value == undefined || value == null) {
      return isValid = true;
    }
    return isValid;
  }

  static validateNullorUndefined(value: any): boolean {
    let isValid = false;
    if (value == undefined || value == null) {
      return isValid = true;
    }
    return isValid;
  }

  
}
