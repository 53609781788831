import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { CommonModule } from '@angular/common';
import { LoginComponent } from '../app/login/login.component';
import { LandingComponent } from '../app/landing/landing.component';
import { ManageContractorComponent } from '../app/manage-contractor/manage-contractor.component';
import { AddContractorComponent } from '../app/add-contractor/add-contractor.component';
import { EditContractorComponent } from '../app/edit-contractor/edit-contractor.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { AudithistoryComponent } from './audithistory/audithistory.component';

const appRoutes: Routes = [
  { path: 'home', component: LandingComponent },
  { path: 'managecontractor', component: ManageContractorComponent },
  { path: 'addcontractor', component: AddContractorComponent },
  { path: 'editcontractor/:id', component: EditContractorComponent },
  { path: 'audithistory', component: AudithistoryComponent },
  { path: 'audithistory/:upn', component: AudithistoryComponent },
  { path: 'bulkupload', component: BulkUploadComponent },
  { path: 'login', component: LoginComponent },
  //{ path: 'forgotpassword/:id', component: ForgotPasswordComponent },
  //{ path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: '**', component: LoginComponent }
];
export const routes: ModuleWithProviders = RouterModule.forRoot(appRoutes);
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { useHash: false, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }

