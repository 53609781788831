import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { TranslateService } from '../translator/translate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { trigger, state, style, transition, animate } from '@angular/animations';

interface UserCache {
  upn: string,
  oid: string,
  role: string
}

interface AuditEntity {
  partitionKey: string,
  rowKey: string,
  timestamp: string,
  userObjectID: string,
  user: string,
  userType: string,
  eventType: string,
  impactedIdentity: string,
  dataObject: any
}

interface ContractingCompany {
  companyCode: string,
  companyName: string,
  companyDisplayName: string,
  country: string,
  region: string
}

interface UpdateUserModel {
  FirstName: string,
  LastName: string,
  PrimaryEmail: string,
  MobileNumber: string,
  StartDate: string,
  EndDate: string,
  OrgID: string,
  OrgName: string,
  CompanyID: string,
  CompanyName: string
}

interface ADUserModel {
  id: string,
  otherMails: string,
  givenName: string,
  surname: string,
  mobilePhone: string,
  department: string,
  officeLocation: string
}

@Component({
  selector: 'app-audithistory',
  templateUrl: './audithistory.component.html',
  styleUrls: ['./audithistory.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})

export class AudithistoryComponent implements OnInit {
  @ViewChild('auditDataTable') private _table: Table;

  userCache: UserCache = <UserCache>{};
  isSuperAdmin: boolean;
  auditData: AuditEntity[];
  columns: any[];
  filterEventTypes: SelectItem[];
  filterRole: SelectItem[];
  selectedEvent: any;
  selectedRole: any;
  filterAdmin: string;
  upnListDB: string[];
  selectedUPN: string;
  companyName: string;
  selectedDept: string;
  deptPath: string;

  filterFromDate: Date;
  filterToDate: Date;

  firstNameLabel: string;
  lastNameLabel: string;
  userInfo: any;

  constructor(private _loaderService: LoaderService, private translate: TranslateService, private _router: Router,
    private _userService: UserService, private route: ActivatedRoute) { }

  ngOnInit() {
    let todaysDate = new Date();
    todaysDate.setMonth(todaysDate.getMonth() - 6);
    this.filterFromDate = todaysDate;
    this.filterToDate = new Date();

    this.createGridColumns();
    this.initFunctions();
    this.filterEventTypes = [
      { label: 'All', value: null },
      { label: 'Create User', value: 'Create User' },
      { label: 'Update User', value: 'Update User' },
      { label: 'Enable User', value: 'Enable User' },
      { label: 'Disable User', value: 'Disable User' },
      { label: 'Reset Password', value: 'Reset Password' },
      { label: 'Delete User', value: 'Delete User' },
      { label: 'Dept Level Change Job', value: 'Dept Level Change Job' },
      { label: 'Dept Delete Job', value: 'Dept Delete Job' },
      { label: 'Dept Rename Job', value: 'Dept Rename Job' }
    ];
    this.filterRole = [
      { label: 'Admin', value: 'Admin' },
      { label: 'System', value: 'System' }
    ];
  }

  createGridColumns() {
    this.columns = [
      { field: 'timestamp', header: 'Updated On', control: 'textbox', format: 'date' },
      { field: 'eventType', header: 'Event', control: 'textbox', format: 'string' },
      { field: 'user', header: 'Updated By', control: 'textbox', format: 'string' },
      { field: 'userType', header: 'Updated By-Role', control: 'textbox', format: 'string' }
    ];
  }

  initFunctions() {
    this._userService.GetCachedUser().then(result => {
      this.userCache.upn = result[0].user_id;
      this.userCache.oid = result[0].user_claims.find(t => t.typ.endsWith('objectidentifier')).val;

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.SuperAdmin')) {
        this.userCache.role = "EIMPortal.SuperAdmin"; this.isSuperAdmin = true;
      } else {
        this.isSuperAdmin = false;
      }

      if (!this.isSuperAdmin) {
        this._router.navigate(['login/']);
      }

      this.route.params.subscribe(params => {
        if (params.upn != null) {
          this.getUserProfile(params.upn);
          this.selectedUPN = params.upn;
          var searchAudit =
          {
            ImpactedUPN: params.upn,
            FromDate: this.filterFromDate,
            ToDate: this.filterToDate,
          };
          this._loaderService.display(true);
          this._userService.GetAuditHistory(searchAudit).then(auditDataResult => {
            this.auditData = auditDataResult;
            this._loaderService.display(false);
          }).catch(error => {
            this._loaderService.display(false);
          });
        }
      });

    });
  }

  searchAuditData() {
    if (this._table)
      this._table.reset();

    this.route.params.subscribe(params => {
      if (this.selectedUPN != null && this.selectedUPN.trim() != '') {
        this.getUserProfile(this.selectedUPN);
        var searchAudit =
        {
          ImpactedUPN: this.selectedUPN,
          FromDate: this.filterFromDate,
          ToDate: this.filterToDate,
          EventType: this.selectedEvent,
          AdminUPN: this.filterAdmin,
          UpdatedRole: this.selectedRole
        };
        this._loaderService.display(true);
        this._userService.GetAuditHistory(searchAudit).then(auditDataResult => {
          console.log(auditDataResult);
          this.auditData = auditDataResult;
          this._loaderService.display(false);
        }).catch(error => {
          this._loaderService.display(false);
        });
      }
    });
  }

  getUserProfile(upn: string) {
    this._userService.GetUserProfile(upn).then(response => {
      if (response != null && response != undefined) {
        this.userInfo = response;
        this.GetCompanyName();
        this.SetExistingDepartment();
      }
    });
  }

  private GetCompanyName() {
    this._userService.GetContractingCompanies(this.userInfo.companyID, true, this.userInfo.companyID).then(result => {
      if (result) {
        let companyResult: ContractingCompany[] = result;
        let existingCompany: ContractingCompany = companyResult.find(comp => comp.companyCode == this.userInfo.companyID);
        this.companyName = existingCompany.companyDisplayName;
      }
    }).catch(error => {
    });
  }

  private SetExistingDepartment() {
    if (this.userInfo.orgID != undefined) {
      this._userService.GetDepartmentPath(this.userInfo.orgID).then(result => {
        let deptNode = result;
        if (deptNode != undefined) {
          if (deptNode.message != undefined) {
            this.selectedDept = deptNode.message;
          }
        }
      }).catch(error => {
      });
    }
  }

  searchEimUserUPN(event) {
    let query = event.query;
    if (query.length > 1) {
      this._userService.GetEimUserUpn(query)
        .then(result => {
          if (result != null && result != undefined) {
            this.upnListDB = result;
          } else {
            this.upnListDB = [];
          }
        }).catch(error => {
          console.log(error);
        });
    }
  }

  displayExpandBtn(eventType: string): boolean {
    if (eventType == 'Update User' || eventType == 'Create User' ||
      eventType == 'Dept Level Change Job' || eventType == 'Dept Delete Job' ||
      eventType == 'Dept Rename Job' || eventType == 'Delete User') {
      return true;
    } else {
      return false;
    }
  }

  parseDataObject(eventType: string, rowData: any): any {
    if (eventType == 'Update User' || eventType == 'Create User' || eventType == 'Bulk Create User') {
      return this.UpdateUserDataHtml(rowData);
    } else if (eventType == 'Delete User') {
      let deleteArr: string[] = JSON.parse(rowData.dataObject);
      let deleteUserDB: UpdateUserModel = <any>deleteArr[1];
      let deleteUserAD: ADUserModel = <any>deleteArr[0];
      return this.DeleteUserDataHtml(deleteUserAD, deleteUserDB);
    } else if (eventType == 'Dept Level Change Job' || eventType == 'Dept Delete Job' || eventType == 'Dept Rename Job') {
      let deptArr: string[] = JSON.parse(rowData.dataObject);
      return '<div class="row expand-block"><div class="col-md-1">From:</div><div class="col-md-11">' + deptArr[0] + ' </div></div>' +
        '<div class="row expand-block"><div class="col-md-1">To:</div><div class="col-md-11">' + deptArr[1] + '</div></div>';
    }
    return "<div></div>";
  }

  private DeleteUserDataHtml(deleteUserAD: ADUserModel, deleteUserDB: UpdateUserModel): any {
    return "<div class='row expand-block'>" +
      "<div class='col-md-3'>First Name: " + deleteUserAD.givenName + " </div>" +
      "<div class='col-md-3'>Last Name: " + deleteUserAD.surname + "</div>" +
      "<div class='col-md-3'>Email: " + deleteUserAD.otherMails + "</div>" +
      "<div class='col-md-3'>Mobile Number: " + deleteUserAD.mobilePhone + "</div>" +
      "</div>" +
      "<div class='row expand-block'>" +
      "<div class='col-md-3'>Start Date: " + deleteUserDB.StartDate + " </div>" +
      "<div class='col-md-3'>End Date: " + deleteUserDB.EndDate + "</div>" +
      "<div class='col-md-3'>Organisation: " + deleteUserAD.department + "</div>" +
      "<div class='col-md-3'>Company: " + deleteUserAD.officeLocation + "</div>" +
      "</div>";
  }

  private UpdateUserDataHtml(rowData: AuditEntity): any {
    let updateUserData: UpdateUserModel = JSON.parse(rowData.dataObject);
    let prevDataList = this.auditData.filter(d => d.eventType == 'Update User' || d.eventType == 'Create User' || d.eventType == 'Bulk Create User');
    let prevData: AuditEntity; let prevUpdateUserData: UpdateUserModel;
    if (prevDataList.length > 1) {
      let currentIndex = prevDataList.findIndex(i => i.partitionKey == rowData.partitionKey && i.rowKey == rowData.rowKey);
      if (currentIndex > 0) {
        prevData = prevDataList[currentIndex - 1];
        prevUpdateUserData = JSON.parse(prevData.dataObject);
      } else {
        prevData = rowData; prevUpdateUserData = JSON.parse(rowData.dataObject);
      }
    }

    console.log(prevUpdateUserData);
    let design: string = "<div class='row'>" +
      "<div class='col-md-6 expand-block expand-block-border'><div class='expandblock-head'>Updated On (Old): " + this.getDateTimeString(prevData.timestamp) +
      "</div><div class='row expand-block'>";
    if (prevUpdateUserData.FirstName != updateUserData.FirstName) { design += "<div class='col-md-6 row-Expand-col'><label class='input-Label'>First Name:</label><div class='updatetext-style'>" + prevUpdateUserData.FirstName + "</div></div>" }
    else {
      design += "<div class='col-md-6 row-Expand-col'><label class='input-Label'>First Name:</label>" + prevUpdateUserData.FirstName + "</div>"
    }
    if (prevUpdateUserData.LastName != updateUserData.LastName) { design += "<div class='col-md-6 row-Expand-col'><label class='input-Label'>Last Name:</label><div class='updatetext-style'>" + prevUpdateUserData.LastName + "</div></div></div><div class='row expand-block'>" }
    else {
      design += "<div class='col-md-6 row-Expand-col'><label class='input-Label'>Last Name:</label>" + prevUpdateUserData.LastName + "</div></div><div class='row expand-block'>"
    }
    if (prevUpdateUserData.PrimaryEmail != updateUserData.PrimaryEmail) { design += "<div class='col-md-6'><label class='input-Label'>Email:</label><div class='updatetext-style'>" + prevUpdateUserData.PrimaryEmail + "</div></div>" }
    else {
      design += "<div class='col-md-6'><label class='input-Label'>Email:</label>" + prevUpdateUserData.PrimaryEmail + "</div>"
    }
    if (prevUpdateUserData.MobileNumber != updateUserData.MobileNumber) { design += "<div class='col-md-6'><label class='input-Label'>Mobile Number:</label><div class='updatetext-style'>" + prevUpdateUserData.MobileNumber + "</div></div></div><div class='row expand-block'>" }
    else {
      design += "<div class='col-md-6'><label class='input-Label'>Mobile Number:</label>" + prevUpdateUserData.MobileNumber + "</div></div><div class='row expand-block'>"
    }
    //design += "</div><div class='row expand-block'>";
    if (this.getDateTimeString(prevUpdateUserData.StartDate) != this.getDateTimeString(updateUserData.StartDate)) { design += "<div class='col-md-6'><label class='input-Label'>Start Date:</label><div class='updatetext-style'>" + this.getDateTimeString(prevUpdateUserData.StartDate) + "</div></div>" }
    else {
      design += "<div class='col-md-6'><label class='input-Label'>Start Date:</label>" + this.getDateTimeString(prevUpdateUserData.StartDate) + " </div>"
    }
    if (this.getDateTimeString(prevUpdateUserData.EndDate) != this.getDateTimeString(updateUserData.EndDate)) { design += "<div class='col-md-6'><label class='input-Label'>End Date:</label><div class='updatetext-style'>" + this.getDateTimeString(prevUpdateUserData.EndDate) + "</div></div></div><div class='row expand-block'>" }
    else {
      design += "<div class='col-md-6'><label class='input-Label'>End Date:</label>" + this.getDateTimeString(prevUpdateUserData.EndDate) + "</div></div><div class='row expand-block'>"
    }
    if (prevUpdateUserData.OrgName != updateUserData.OrgName) { design += "<div class='col-md-12'><label class='input-Label'>Organisation:</label><div class='updatetext-style'>" + prevUpdateUserData.OrgName + "</div></div></div><div class='row expand-block'>" }
    else {
      design += "<div class='col-md-12'><label class='input-Label'>Organisation:</label>" + prevUpdateUserData.OrgName + "</div></div><div class='row expand-block'>"
    }
    if (prevUpdateUserData.CompanyName != updateUserData.CompanyName) { design += "<div class='col-md-12'><label class='input-Label'>Company:</label> <div class='updatetext-style'>" + prevUpdateUserData.CompanyName + "</div></div></div>" }
    else {
      design += "<div class='col-md-12'><label class='input-Label'>Company:</label>" + prevUpdateUserData.CompanyName + "</div></div>"
    }
    design += "<div class='row expand-block'>" +
      "<div class='col-md-6'><label class='input-Label'>Updated By:</label>" + rowData.user + "</div>" +
      "<div class='col-md-6'><label class='input-Label'>Responsible Person:</label>" + this.userInfo.responsiblePerson + "</div></div></div>";
    design += "<div class='col-md-6 expand-block expand-block-border'><div class='expandblock-head'>Updated On: " + this.getDateTimeString(rowData.timestamp) +
      "</div><div class='row expand-block'>" +
      "<div class='col-md-6 row-Expand-col'><label class='input-Label'>First Name:</label>" + updateUserData.FirstName + "</div>" +
      "<div class='col-md-6 row-Expand-col'><label class='input-Label'>Last Name:</label>" + updateUserData.LastName + "</div>" +
      "</div><div class='row expand-block'><div class='col-md-6'><label class='input-Label'>Email:</label>" + updateUserData.PrimaryEmail + "</div>" +
      "<div class='col-md-6'><label class='input-Label'>Mobile Number:</label>" + updateUserData.MobileNumber + "</div></div>" +
      "<div class='row expand-block'>" +
      "<div class='col-md-6'><label class='input-Label'>Start Date:</label>" + this.getDateTimeString(updateUserData.StartDate) + "</div>" +
      "<div class='col-md-6'><label class='input-Label'>End Date:</label>" + this.getDateTimeString(updateUserData.EndDate) + "</div>" +
      "</div><div class='row expand-block'><div class='col-md-12'><label class='input-Label'>Organisation:</label>" + updateUserData.OrgName + "</div>" +
      "</div><div class='row expand-block'><div class='col-md-12'><label class='input-Label'>Company:</label>" + updateUserData.CompanyName + "</div>" +
      "</div>" +
      "<div class='row expand-block'>" +
      "<div class='col-md-6'><label class='input-Label'>Updated By:</label>" + rowData.user + "</div>" +
      "<div class='col-md-6'><label class='input-Label'>Responsible Person:</label>" + this.userInfo.responsiblePerson + "</div></div></div></div>";
    return design;
  }

  getDateTimeString(dateString: string): string {
    let dateValue: Date = new Date(dateString);
    let date = dateValue.getFullYear() + '-' + (dateValue.getMonth() + 1) + '-' + dateValue.getDate();
    let time = dateValue.getHours() + ":" + dateValue.getMinutes() + ":" + dateValue.getSeconds();
    return date + ' ' + time;
  }
}
