import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { Helper } from '../helpers/helper';
import { Constants } from '../helpers/constants';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private _http: Http, private appconfig: ConfigurationService) {
  }

  GetCachedUser(): any {
    if (this.appconfig.config.serverenvironment == 'Development') {
      return this._http.get('./assets/mock/me.json').toPromise()
        .then(res => res.json()).catch((Helper.HandleError))
    } else {
      return this._http.get('/.auth/me').toPromise()
        .then(res => res.json()).catch((Helper.HandleError));
    }
  }

  GetUserLanguage(upn: string): any {
    return this._http.get('/api/user/GetUserLanguage?upn=' + upn).toPromise()
      .then(res => res.text()).catch((Helper.HandleError));
  }

  UpdateUserLanguage(lang: any) {
    let options = new RequestOptions({ method: RequestMethod.Post });
    return this._http.post('/api/user/UpdateUserLanguage', lang, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  GetUserProfile(id: string): any {
    return this._http.get(Constants.GET_USER_DETAIL_BY_ID + id).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  GetAuditHistory(auditsearch: any): any {
    let options = new RequestOptions({ method: RequestMethod.Post });
    return this._http.post(Constants.GET_AUDIT_HISTORY, auditsearch, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  SendSmsCode(upn: string): any {
    return this._http.get(Constants.SEND_SMS_CODE + upn).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  VerifyOtpCode(upn: string, otp: string): any {
    return this._http.get(Constants.VERIFY_OTP_CODE + '?upn=' + upn + '&otp=' + otp).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  SendEmailCode(upn: string): any {
    return this._http.get(Constants.SEND_EMAIL_CODE + upn).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  VerifyOtpEmail(upn: string, otp: string): any {
    return this._http.get(Constants.VERIFY_EMAIL_CODE + '?upn=' + upn + '&otp=' + otp).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  GetCountryList(): any {
    return this._http.get(Constants.GET_COUNTRY_LIST).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  GetContractingCompanies(searchText: string, includeDefault: boolean, defaultCompId: string = ''): any {
    return this._http.get(Constants.GET_CONTRACTING_COMPANIES + searchText
      + '&includeDefault=' + includeDefault
      + '&defaultCompId=' + defaultCompId
    ).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  GetEimUserUpn(searchText: string): any {
    return this._http.get(Constants.GET_EIMUSER_UPN + searchText).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  GetDepartments(parentid: string): any {
    return this._http.get(Constants.GET_DEPARTMENT_TREE + parentid).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  GetDepartmentPath(deptid: string): any {
    return this._http.get(Constants.GET_DEPARTMENT_PATH + deptid).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  ValidateUserEmail(emailid: string): any {
    return this._http.get(Constants.VALIDATE_USER_LOGINID + emailid,
      { withCredentials: true }).toPromise()
      .then(res => res.text()).catch((Helper.HandleError));
  }

  CreateEIMUser(eimUser: any): any {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    return this._http.post(Constants.CREATE_EIM_USER, eimUser, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  UpdateEIMUser(eimUser: any) {
    let options = new RequestOptions({ method: RequestMethod.Post });
    return this._http.post(Constants.UPDATE_EIM_USER, eimUser, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  EnableEIMUser(userDetails: any): any {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    let body = userDetails;
    return this._http.post(Constants.ENABLE_EIMUSER, body, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  DisableEIMUser(userDetails: any): any {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    let body = userDetails;
    return this._http.post(Constants.DISABLE_EIMUSER, body, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  ResetEIMUserPassword(userDetails: any): any {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    let body = userDetails;
    return this._http.post(Constants.RESET_USER_PASSWORD, body, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  DeleteEIMUser(userDetails: any): any {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    let body = userDetails;
    return this._http.post(Constants.DELETE_USER, body, options).toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

  UploadBulkUser(file: any, upn: string, name: string): any {
    let headers = new Headers({ 'Content-Disposition': 'form-data', 'Content-Type': 'multipart/form-data' });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(Constants.UPLOAD_BULKUSERS + '?upn=' + upn + '&name=' + name, file).toPromise()
      .then(res => res.json());
  }

}
