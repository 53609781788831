import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MenuItem, SelectItem, SelectItemGroup, TreeNode } from 'primeng/api';

import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
import { Message } from 'primeng/api';
import { ManageContractorService } from '../services/manage-contractor.service';
import { CustomValidator } from '../helpers/CustomValidator';
import { TranslateService } from '../translator/translate.service';
import { forEach } from '@angular/router/src/utils/collection';

interface ContractingCompany {
  companyCode: string,
  companyName: string,
  companyDisplayName: string,
  country: string,
  region: string
}
interface Country {
  id: number,
  countryname: string,
  phonecode: string,
  countrycode: string
}
interface Contractor {
  adObjectID: string,
  upn: string,
  firstName: string,
  lastName: string,
  jobTitle: string,
  primaryEmail: string,
  mobileNumber: string,
  status: boolean,
  startDate: Date,
  endDate: Date,
  lastValidatedDate: Date,
  orgID: string,
  orgName: string,
  companyID: string,
  companyName: string,
  createdBy: string,
  auditData: any,
  updatedBy: string,
  responsiblePerson: string,
  loginId: string
}
interface UserCache {
  upn: string,
  oid: string,
  role: string
}

@Component({
  selector: 'app-edit-contractor',
  templateUrl: './edit-contractor.component.html',
  styleUrls: ['./edit-contractor.component.css']
})
export class EditContractorComponent implements OnInit {
  applications: any[];
  userInfo: Contractor;
  isSuperAdmin = false;

  extFirstName: string;
  extLastName: string;
  extEmail: string;
  extMobphone: string;
  extEndDate: Date;
  extDeptId: string;
  extCompId: string

  countryListDB: Country[];
  phoneCodes: SelectItemGroup[];
  selectedCountry: Country;
  companyList: SelectItem[];
  companyListDB: ContractingCompany[];
  selectedCompany: ContractingCompany;
  existingCompany: ContractingCompany;

  deptPopupDisplay: boolean = false;
  deptTree: TreeNode[]
  selectedDeptPopup: TreeNode;
  selectedDeptPage: TreeNode;
  selectedDeptLabelPopup: string;
  selectedDeptLabelPage: string;
  existingDeptLabelPopup: string;
  selectedMobileNumber: string;

  minDate: Date;
  maxDate: Date;
  selectedEndDate: Date;
  disablecontrol: boolean = true;
  deptTreeLoading: boolean = false;
  actions: MenuItem[];
  menuItems: MenuItem[];
  home: MenuItem;
  validationMessages: Message[] = [];
  updateButtonEnable: boolean = true;
  userCache: UserCache = <UserCache>{};

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    if (this.isExistingData()) {
      return true;
    } else {
      return false; //cannot set custom message.
    }
    //event.returnValue = false;
  }

  canDeactivate() {
    if (this.isExistingData()) {
      return true;
    } else {
      return confirm(this.translate.data['MSGONPAGECLOSE']);
    }
  }

  constructor(private _loaderService: LoaderService, private translate: TranslateService, private _router: Router,
    private _userService: UserService, private route: ActivatedRoute, private _confirmationService: ConfirmationService,
    private _manageContractorService: ManageContractorService) { }

  ngOnInit() {
    this.initFunctions();
  }

  initFunctions() {
    this._userService.GetCachedUser().then(result => {
      this.userCache.upn = result[0].user_id;
      this.userCache.oid = result[0].user_claims.find(t => t.typ.endsWith('objectidentifier')).val;

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.SuperAdmin')) {
        this.userCache.role = "EIMPortal.SuperAdmin"; this.isSuperAdmin = true;
      } else {
        this.isSuperAdmin = false;
      }

      if (result[0].user_claims.some(t => t.typ == 'roles' && t.val == 'EIMPortal.Admin')) {
        this.userCache.role = "EIMPortal.Admin";
      } else {
        this.userCache.role = result[0].user_claims.find(t => t.typ == 'roles').val;
      }

      if (this.userCache.role == "EIMPortal.Admin") {
        this.loadInitialValues();
        this.route.params.subscribe(params => {
          if (params.id != null) {
            //this._loaderService.display(true);
            this._userService.GetUserProfile(params.id).then(edituser => {
              if (edituser != undefined) {
                this.SetDataValidationFields(edituser);
                this.selectedEndDate = new Date(edituser.endDate);
                if (this.userInfo.status)
                  this.disablecontrol = false;
                else
                  this.disablecontrol = true;

                //Setting the mobile and country code in two fields
                this.LoadCountryandSelectvalue();
                //Retrieving the company data and selecting the companyle
                this.LoadCompanyDataandSelectValue();
                this._loaderService.display(false);
                //Setting the saved department
                this.SetExistingDepartment();
              }
            }).catch(error => {
              this._loaderService.display(false);
            });
          }
        });
      } else {
        this._router.navigate(['login/']);
      }
    });
  }

  private SetDataValidationFields(edituser: any) {
    this.userInfo = edituser;
    this.extFirstName = edituser.firstName;
    this.extLastName = edituser.lastName;
    this.extEmail = edituser.primaryEmail;
    this.extMobphone = edituser.mobileNumber;
    this.extEndDate = new Date(edituser.endDate);
    this.extCompId = edituser.companyID;
    this.extDeptId = edituser.orgID;
  }

  private loadInitialValues() {
    let today = new Date();
    this.minDate = new Date();
    this.minDate.setDate(today.getDate());
    this.maxDate = new Date();
    this.maxDate.setFullYear(today.getFullYear() + 1);
    this.LoadDepartmentParent();
  }

  private ConfirmUpdateAndProcess() {
    let _mobileNumber = this.selectedCountry.phonecode + ' ' + this.selectedMobileNumber.replace(/\s/g, "");
    this.userInfo.mobileNumber = _mobileNumber;

    if (this.selectedDeptPage)
      this.userInfo.orgID = this.selectedDeptPage.data;
    if (this.selectedCompany)
      this.userInfo.companyID = this.selectedCompany.companyCode;
    this.userInfo.updatedBy = this.userCache.upn;
    this.userInfo.responsiblePerson = this.userCache.upn;

    let confirmMessage: string;
    if (this.isExistingData()) {
      confirmMessage = this.translate.data['QNNOCHANGEUPDATE'];;
    }
    else {
      confirmMessage = this.translate.data['QNUSERUPDATE'];
    }

    this._confirmationService.confirm({
      message: confirmMessage,
      header: this.translate.data['CONFIRMATION'],
      acceptLabel: this.translate.data['YES'],
      rejectLabel: this.translate.data['NO'],
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._loaderService.display(true);

        let date = new Date(this.selectedEndDate.toISOString());
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        this.userInfo.endDate = new Date(date.getTime() - userTimezoneOffset);

        var request = {
          requestBody: this.userInfo,
          auditData: {
            userObjectId: this.userCache.oid, //oid
            user: this.userCache.upn,
            userType: "Admin",
            eventType: "Update User"
          }
        };
        this.RemoveValidationMessage('updateuser');
        this.RemoveValidationMessage('updateuser-MOBILEPHONE');
        this.RemoveValidationMessage('updateuser-EMAIL');
        this.RemoveValidationMessage('createuser-EMAIL_FORMAT');
        this._userService.UpdateEIMUser(request).then(result => {
          this._loaderService.display(false);
          if (result != null && result != undefined) {
            if (result.isSuccess) {
              this.validationMessages.push({ id: 'updateuser', severity: 'success', summary: this.translate.data['MSGUPDATEDUSER'] });
              this.initFunctions();
            } else {
              if (result.errorList && result.errorList.length > 0) {
                for (var i = 0; i < result.errorList.length; i++) {
                  let error = result.errorList[i];
                  if (error.errorKey == 'MOBILEPHONE') {
                    this.validationMessages.push({ id: 'updateuser-' + error.errorKey, severity: 'error', summary: this.translate.data['VALIDATEEXISTMOBNO'].replace('[UPN]', error.data) });
                  } else if (error.errorKey == 'EMAIL') {
                    this.validationMessages.push({ id: 'updateuser-' + error.errorKey, severity: 'error', summary: this.translate.data['VALIDATEEXISTMAIL'].replace('[UPN]', error.data) });
                  } else if (error.errorKey == 'EMAIL_FORMAT') {
                    this.validationMessages.push({ id: 'createuser-' + error.errorKey, severity: 'error', summary: this.translate.data['VALIDATEEMAILPATTERN'] });
                  }
                }
              } else {
                this.validationMessages.push({ id: 'updateuser', severity: 'error', summary: result.message });
              }
            }
          }
          else {
            this.validationMessages.push({ id: 'updateuser', severity: 'error', summary: 'No response from EIMService' });
          }
        }).catch(error => {
          this._loaderService.display(false);
        });
      },
      reject: () => {
        this._loaderService.display(false);
        return;
      }
    });
  }

  private UpdateUserStatus() {
    let user: any = this.userInfo;
    let request: any;

    let ConfirmQuestion: string;
    let UpdateSuccessMessage: string;

    if (this.userInfo.status == true) {
      ConfirmQuestion = this.translate.data['QNDISABLEUSER'];

      request = {
        requestBody: { adObjectId: user.adObjectID, upn: user.upn },
        auditData: {
          userObjectId: this.userCache.oid, //oid
          user: this.userCache.upn,
          userType: "Admin",
          eventType: "Disable User"
        }
      };
    }
    else {
      let enddate: Date = new Date();
      enddate.setDate(enddate.getDate() + 30);
      ConfirmQuestion = this.translate.data['QNENABLEUSER'];

      request = {
        requestBody: { "adObjectID": user.adObjectID, "endDate": enddate, upn: user.upn },
        auditData: {
          userObjectId: this.userCache.oid, //oid
          user: this.userCache.upn,
          userType: "Admin",
          eventType: "Enable User"
        }
      };

    }

    this._confirmationService.confirm({
      message: ConfirmQuestion,
      header: this.translate.data['CONFIRMATION'],
      acceptLabel: this.translate.data['YES'],
      rejectLabel: this.translate.data['NO'],
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._loaderService.display(true);
        this.RemoveValidationMessage('updatestatus');
        if (this.userInfo.status == true) {
          this._userService.DisableEIMUser(request).then(result => {
            if (result != null && result != undefined) {
              if (result.isSuccess) {
                this.initFunctions();
                this.validationMessages.push({ id: 'updatestatus', severity: 'success', summary: this.translate.data['MSGDISABLEDUSER'] });
                this._loaderService.display(false);
              } else {
                this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: result.message });
                this._loaderService.display(false);
              }
            }
            else {
              this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: 'No response from EIM Service' });
              this._loaderService.display(false);
            }
          }).catch(error => {
            this._loaderService.display(false);
            this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: error });
          });
        } else {
          this._userService.EnableEIMUser(request).then(result => {
            this._loaderService.display(false);
            if (result != null && result != undefined) {
              if (result.isSuccess) {
                this.validationMessages.push({ id: 'updatestatus', severity: 'success', summary: this.translate.data['MSGENABLEDUSER'] });
                this.initFunctions();
              } else {
                this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: result.message });
              }
            }
            else {
              this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: 'No response from EIM Service' });
            }
          }).catch(error => {
            this._loaderService.display(false);
            this.validationMessages.push({ id: 'updatestatus', severity: 'error', summary: error });
          });
        }
      },
      reject: () => {
        this._loaderService.display(false);
        return;
      }
    });
  }

  private SetExistingDepartment() {
    if (this.userInfo.orgID != undefined) {
      this._loaderService.display(true);
      this._userService.GetDepartmentPath(this.userInfo.orgID).then(result => {
        let deptNode = result;
        this.selectedDeptLabelPopup = '';
        if (deptNode != undefined) {
          if (deptNode.message != undefined) {
            this.selectedDeptLabelPage = deptNode.message;
            this.existingDeptLabelPopup = deptNode.message;
          }
        }
        this._loaderService.display(false);
      }).catch(error => {
        this._loaderService.display(false);
      });
    }
  }

  private LoadDepartmentParent() {
    this._userService.GetDepartments("0").then(result => {
      this.deptTree = result;
    }).catch(error => {
    });
  }

  private LoadCompanyDataandSelectValue() {
    this._userService.GetContractingCompanies(this.userInfo.companyID, true, this.userInfo.companyID).then(result => {
      if (result) {
        let companyResult: ContractingCompany[] = result;
        this.existingCompany = companyResult.find(comp => comp.companyCode == this.userInfo.companyID);
        this.selectedCompany = this.existingCompany;
      }
    }).catch(error => {
    });
  }

  private LoadCountryandSelectvalue() {
    //Loading country drop down
    this._userService.GetCountryList().then(result => {
      this.countryListDB = result;
      let countries: SelectItem[] = this.countryListDB.map<SelectItem>(cn =>
        ({ label: cn.countryname, value: cn }));
      this.phoneCodes = [{
        label: '--Primary--',
        items: [
          { label: "Australia", value: { id: 13, countryname: "Australia", phonecode: "+61", countrycode: "AU" } },
          { label: "Chile", value: { id: 283, countryname: "Chile", phonecode: "+56", countrycode: "CL" } },
          { label: "Singapore", value: { id: 195, countryname: "Singapore", phonecode: "+65", countrycode: "SG" } },
          { label: "United States", value: { id: 13, countryname: "United States", phonecode: "+1", countrycode: "US" } }
        ]
      },
      {
        label: '--Others--',
        items: countries
      }];
      //spliting the phone number field and selecting country code
      let _mobNo = this.userInfo.mobileNumber;
      if (_mobNo != null) {
        var mobNoArr = _mobNo.split(' ');
        if (mobNoArr.length >= 2) {
          this.selectedCountry = this.countryListDB.find(cn => cn.phonecode == (mobNoArr[0]));
          this.selectedMobileNumber = mobNoArr[1];
        }
      }

    }).catch(error => {
    });
  }

  companyOnBlur(event: any) {
    if (!this.selectedCompany) {
      this.selectedCompany = this.existingCompany;
      event.target.value = this.selectedCompany.companyDisplayName;
    }
    else if (this.selectedCompany && event && event.target) {
      if (this.selectedCompany.companyDisplayName != event.target.value || event.target.value.trim() == '') {
        this.selectedCompany = this.existingCompany;
        event.target.value = this.selectedCompany.companyDisplayName;
      }
    }
  }

  showDeptDialog() {
    this.deptPopupDisplay = true;
  }

  deptPopupOK() {
    if (this.selectedDeptPopup != undefined) {
      this.selectedDeptPage = this.selectedDeptPopup;
      this.selectedDeptLabelPage = this.selectedDeptLabelPopup;
    }
    else { this.selectedDeptPage = null; }
    this.deptPopupDisplay = false;
  }

  deptPopupCancel() {
    this.deptPopupDisplay = false;
  }

  onDeptNodeSelect(node: TreeNode) {
    this.selectedDeptLabelPopup = node.label;
    this.setSelectedDeptParentLabelPopup(node);
  }

  onDeptNodeExpand(event) {
    if (event.node) {
      this.deptTreeLoading = true;
      this._userService.GetDepartments(event.node.data).then(nodes => {
        event.node.children = nodes;
        this.deptTreeLoading = false;
      }).catch(error => {
      });
    }
  }

  setSelectedDeptParentLabelPopup(_selectedDept: TreeNode) {
    if (_selectedDept.parent != undefined && _selectedDept.parent != null) {
      this.selectedDeptLabelPopup = _selectedDept.parent.label + ' > ' + this.selectedDeptLabelPopup;
      this.setSelectedDeptParentLabelPopup(_selectedDept.parent);
    };
  }

  setSelectedDeptParentLabelPage(_userDept: TreeNode) {
    if (_userDept.parent != undefined && _userDept.parent != null) {
      this.selectedDeptLabelPage = _userDept.parent.label + ' > ' + this.selectedDeptLabelPage;
      this.setSelectedDeptParentLabelPage(_userDept.parent);
    };
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  enableField(userInfo: any) {
    if (userInfo.status == false || userInfo.accountType == 'B2B') {
      return false;
    } else {
      return true;
    }
  }

  AlphaNumericValidator($event): boolean {
    return CustomValidator.AlphaNumericValidator($event);
  }

  filterCompanies(event) {
    let query = event.query.trim();
    if (query.length > 2) {
      this._userService.GetContractingCompanies(query, true, this.userInfo.companyID).then(result => {
        if (result != null && result != undefined) {
          this.companyListDB = result;
        } else {
          this.companyListDB = [];
        }
      }).catch(error => {
      });
    }
  }

  validateonSubmit(): boolean {
    let fname, lname, country, mobile, enddate, dept, comp: boolean;
    fname = this.firstNameValidate(this.userInfo.firstName);
    lname = this.lastNameValidate(this.userInfo.lastName);
    country = this.countryCodeValidate();
    mobile = this.mobilePhoneValidate(this.selectedMobileNumber);
    enddate = this.endDateValidate();
    //dept = this.departmentValidate();
    comp = this.companyValidate();
    this.emailIdOnly(this.userInfo.primaryEmail);
    if (fname && lname && country && mobile && enddate && comp) {
      return true;
    } else {
      return false;
    }
  }

  updateContractor() {
    if (this.validateonSubmit()) {
      if (!CustomValidator.emailValidator(this.userInfo.primaryEmail)) {
        this.AddValiationMessage('emailpattern', this.translate.data['VALIDATEEMAILPATTERN']);
      }
      else {
        this.RemoveValidationMessage('emailpattern');
        if (this.emailDomainValidator(this.userInfo.primaryEmail)) {
          this.RemoveValidationMessage('emailbhpdomain');
          this.ConfirmUpdateAndProcess();
        } else {
          this.AddValiationMessage('emailbhpdomain', this.translate.data['VALIDATEBHPMAIL']);
        }
      }
    }
  }

  private isExistingData() {
    let _mobileNumber = '';
    if (this.selectedCountry != undefined && this.selectedMobileNumber != undefined) {
      _mobileNumber = this.selectedCountry.phonecode + ' ' + this.selectedMobileNumber;
    }

    if (this.userInfo.firstName == this.extFirstName &&
      this.userInfo.lastName == this.extLastName &&
      this.userInfo.primaryEmail == this.extEmail &&
      _mobileNumber == this.extMobphone &&
      this.selectedEndDate.toISOString() == this.extEndDate.toISOString() &&
      this.userInfo.orgID == this.extDeptId &&
      this.userInfo.companyID == this.extCompId) {
      return true;
    } else {
      return false;
    }
  }

  DeleteUser() {
    this._confirmationService.confirm({
      message: this.translate.data['QNDELETEUSER'],
      header: this.translate.data['CONFIRMATION'],
      acceptLabel: this.translate.data['YES'],
      rejectLabel: this.translate.data['NO'],
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._loaderService.display(true);
        let user: any = this.userInfo;

        let request = {
          requestBody: { adObjectId: user.adObjectID, upn: user.upn },
          auditData: {
            userObjectId: this.userCache.oid, //oid
            user: this.userCache.upn,
            userType: "Super Admin",
            eventType: "Delete User"
          }
        };
        this.RemoveValidationMessage('deleteuser');
        this._userService.DeleteEIMUser(request).then(result => {
          this._loaderService.display(false);
          if (result != null && result != undefined) {
            if (result.isSuccess) {
              this.validationMessages.push({ id: 'deleteuser', severity: 'success', summary: this.translate.data['MSGDELETEUSER'] });
              this._router.navigate(['managecontractor/'], { queryParams: { deleteuser: true, upn: this.userInfo.loginId } });
            } else {
              this.validationMessages.push({ id: 'deleteuser', severity: 'error', summary: result.message });
            }
          }
          else {
            this.validationMessages.push({ id: 'deleteuser', severity: 'error', summary: 'No response from EIM Service' });
          }
        }).catch(error => {
          this._loaderService.display(false);
          this.validationMessages.push({ id: 'deleteuser', severity: 'error', summary: error });
        });
      },
      reject: () => {
        return;
      }
    });
  }

  OpenAuditHistory() {
    window.open("/audithistory/" + this.userInfo.upn, "_blank");
  }

  ResetPassword() {
    this._confirmationService.confirm({
      message: this.translate.data['QNRESETPWD'],
      header: this.translate.data['CONFIRMATION'],
      acceptLabel: this.translate.data['YES'],
      rejectLabel: this.translate.data['NO'],
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._loaderService.display(true);
        let user: any = this.userInfo;

        let request = {
          requestBody: { adObjectId: user.adObjectID, upn: user.upn },
          auditData: {
            userObjectId: this.userCache.oid, //oid
            user: this.userCache.upn,
            userType: "Admin",
            eventType: "Reset Password"
          }
        };
        this.RemoveValidationMessage('resetpwd');
        this._userService.ResetEIMUserPassword(request).then(result => {
          this._loaderService.display(false);
          if (result != null && result != undefined) {
            if (result.isSuccess) {
              this.validationMessages.push({ id: 'resetpwd', severity: 'success', summary: this.translate.data['MSGRESETPWD'] });
            } else {
              this.validationMessages.push({ id: 'resetpwd', severity: 'error', summary: result.message });
            }
          }
          else {
            this.validationMessages.push({ id: 'resetpwd', severity: 'error', summary: 'No response from EIM Service' });
          }
        }).catch(error => {
          this._loaderService.display(false);
          this.validationMessages.push({ id: 'resetpwd', severity: 'error', summary: error });
        });
      },
      reject: () => {
        return;
      }
    });
  }

  emailIdOnly(value: any) {
    if (!CustomValidator.emailValidator(value)) {
      this.AddValiationMessage('emailpattern', this.translate.data['VALIDATEEMAILPATTERN']);
    }
    else {
      this.RemoveValidationMessage('emailpattern');
      if (this.emailDomainValidator(value)) {
        this.RemoveValidationMessage('emailbhpdomain');
      } else {
        this.AddValiationMessage('emailbhpdomain', this.translate.data['VALIDATEBHPMAIL']);
      }
    }
  }

  emailDomainValidator(email): boolean {
    let valid = true;
    if (email && email.indexOf("@") != -1) {
      let [_, domain] = email.split("@");
      if (domain == "bhp.com" || domain == "bhpbilliton.com") {
        valid = false;
      }
    }
    return valid;
  }

  firstNameValidate(fname: any): boolean {
    let valid = true;
    if (fname == null || fname == undefined || fname.trim() == "") {
      valid = false;
      this.AddValiationMessage('fname', this.translate.data['VALIDATEFN']);
    } else {
      this.RemoveValidationMessage('fname');
    }
    return valid;
  }

  lastNameValidate(lname: any): boolean {
    let valid = true;
    if (lname == null || lname == undefined || lname.trim() == "") {
      valid = false;
      this.AddValiationMessage('lname', this.translate.data['VALIDATELN']);
    } else {
      this.RemoveValidationMessage('lname');
    }
    return valid;
  }

  endDateValidate(): boolean {
    let valid = true;
    if (this.selectedEndDate == null || this.selectedEndDate == undefined) {
      valid = false;
      this.AddValiationMessage('enddate', this.translate.data['VALIDATEENDDATE']);
    } else {
      this.RemoveValidationMessage('enddate');
    }
    return valid;
  }

  companyValidate(): boolean {
    let valid = true;
    if (this.selectedCompany == null || this.selectedCompany == undefined) {
      valid = false;
      this.AddValiationMessage('company', this.translate.data['VALIDATEEXTORG']);
    } else {
      this.RemoveValidationMessage('company');
    }
    return valid;
  }

  mobilePhoneValidate(phone: any): boolean {
    let valid = true;
    if (phone == null || phone == undefined || phone.trim() == "" || /[^0-9]/g.test(phone)) {
      valid = false;
      this.AddValiationMessage('phone', this.translate.data['VALIDATEMOBNO']);
    } else {
      this.RemoveValidationMessage('phone');
      //if (this.selectedCountry) {
      //  this.mobileNumberDuplicationCheck();
      //}
    }
    return valid;
  }

  countryCodeValidate(): boolean {
    let valid = true;
    if (this.selectedCountry == null || this.selectedCountry == undefined) {
      valid = false;
      this.AddValiationMessage('country', this.translate.data['VALIDATECOUNTRY']);
    } else {
      this.RemoveValidationMessage('country');
      //if (this.selectedMobileNumber && this.selectedMobileNumber.trim() != "") {
      //  this.mobileNumberDuplicationCheck();
      //}
    }
    return valid;
  }

  departmentValidate(): boolean {
    let valid = true;
    if (this.selectedDeptPage == null || this.selectedDeptPage == undefined) {
      valid = false;
      this.AddValiationMessage('department', this.translate.data['VALIDATEDEPT']);
    } else {
      this.RemoveValidationMessage('department');
    }
    return valid;
  }

  private AddValiationMessage(id: string, summary: string) {
    let msgExist = this.validationMessages.find(msg => msg.id == id);
    if (!msgExist) {
      this.validationMessages.push({ id: id, severity: 'error', summary: summary });
    }
  }

  private RemoveValidationMessage(id: string) {
    for (var i = this.validationMessages.length - 1; i >= 0; i--) {
      if (this.validationMessages[i].id === id) {
        this.validationMessages.splice(i, 1);
      }
    }
  }
}
