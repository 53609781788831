import { Injectable } from '@angular/core';
import { TranslateService } from '../translator/translate.service';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { Http } from '@angular/http';
import { Helper } from '../helpers/helper';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  compList: any;
  constructor(private translate: TranslateService, private http: HttpClient, private _http: Http) { }

  setLang(lang: string) {
    console.log(lang);
    this.translate.use(lang);
  }

  public getCountryPhoneCodes(): Observable<any> {
    return this.http.get("./assets/resource/phonecodes.json")
  }

  GetAzureADApps(): any {
    return this._http.get('/api/user/GetAzureADApps').toPromise()
      .then(res => res.json()).catch((Helper.HandleError));
  }

}
