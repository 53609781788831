import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';

import { Configuration } from './../helpers/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  
  private configData: Configuration;

  // Inject the http service and the app's BASE_URL
  constructor(
    private http: Http) { }

  // Call the ClientConfiguration endpoint, deserialize the response,
  // and store it in this.configData.
  loadConfigurationData(): Promise<Configuration> {
    return this.http.get('/api/user/appconfiguration')
      .toPromise()
      .then((response: Response) => {
        this.configData = response.json();
        return this.configData;
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // A helper property to return the config object
  get config(): Configuration {
    return this.configData;
  }
}
