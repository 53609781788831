import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule, Http, XHRBackend, RequestOptions } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { TranslatePipe } from '../app/translator/translate.pipe';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { httpFactory } from "./helpers/http.factory";

import { TranslateService } from '../app/translator/translate.service';
import { CommonService } from '../app/services/common.service';
import { ConfigurationService } from './services/configuration.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { FooterComponent } from './footer/footer.component';
import { ManageContractorComponent } from './manage-contractor/manage-contractor.component';
import { AdminNavBarComponent } from './adminnavbar/adminnavbar.component';
import { AddContractorComponent } from './add-contractor/add-contractor.component';
import { EditContractorComponent } from './edit-contractor/edit-contractor.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { AudithistoryComponent } from './audithistory/audithistory.component';

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { LoaderService } from '../app/services/loader.service';
import { TreeModule } from 'primeng/tree';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
import { InputSwitchModule } from 'primeng/inputswitch';


export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    LandingComponent,
    FooterComponent,
    TranslatePipe,
    ManageContractorComponent,
    AdminNavBarComponent,
    AddContractorComponent,
    EditContractorComponent,
    ForgotPasswordComponent,
    BulkUploadComponent,
    AudithistoryComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    TableModule,
    PaginatorModule,
    DialogModule,
    TreeModule,
    SelectButtonModule,
    FileUploadModule,
    CalendarModule,
    DropdownModule,
    ConfirmDialogModule,
    MessageModule,
    MessagesModule,
    AutoCompleteModule,
    PanelModule,
    InputSwitchModule
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) =>
        () => configService.loadConfigurationData(),
      deps: [ConfigurationService],
      multi: true
    },
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: Http,
      useFactory: httpFactory,
      deps: [XHRBackend, RequestOptions]
    },
    CommonService,
    LoaderService,
    ConfirmationService,
    MessageService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

